import { Autocomplete, TextField } from '@mui/material';
import BoxAlert from 'components/BoxAlert';
import LoadingBox from 'components/LoadingBox';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useAsync } from 'react-use';
import { BuildingPartFormAnswers } from 'services/buildingPart/validationSchema';
import { listLocalBuildingParts } from 'services/offline/requests/buildingAndBuildingPart/listLocalBuildingParts';
import { sortBuildingPartsByFloorLevel } from 'services/projects/sortBuildingParts';

interface Props {
  control: Control<BuildingPartFormAnswers>;
  buildingId: string;
  buildingPartId: string;
  motherBuildingId: string | null;
}

export const MotherBuildingPartField = ({
  control,
  buildingId,
  buildingPartId,
  motherBuildingId,
}: Props): JSX.Element => {
  const intl = useIntl();

  const {
    loading,
    error,
    value: motherBuildingParts,
  } = useAsync(async () => {
    const buildingParts = (await listLocalBuildingParts({ buildingId })).filter(
      buildingPart =>
        buildingPart.motherBuildingPartId === null &&
        buildingPart.id !== buildingPartId,
    );

    return sortBuildingPartsByFloorLevel(buildingParts);
  });

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <BoxAlert contentId={'select-building-part-form.loadingError'} />
      ) : (
        <Controller
          name="motherBuildingPartId"
          control={control}
          defaultValue={motherBuildingId ?? undefined}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="motherBuildingPartId"
              sx={{ marginTop: '16px', marginBottom: '8px' }}
              options={motherBuildingParts ?? []}
              getOptionLabel={option => option.buildingPartName}
              renderInput={params => (
                <TextField
                  data-test="motherBuildingPart"
                  {...params}
                  label={intl.formatMessage({
                    id: 'select-building-part-form.buildingPart',
                  })}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              value={motherBuildingParts?.find(d => d.id === value) ?? null}
              onChange={(_event, item) => {
                onChange(item?.id ?? null);
              }}
            />
          )}
        />
      )}
    </>
  );
};
