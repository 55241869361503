import { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import flattenMessages from 'services/i18n/intl';
import frFRMessages from 'translations/fr-FR.json';
import { CssBaseline, ThemeProvider } from '@mui/material';
import muiTheme from 'services/theme/muiTheme';

import AkiboRoutes from 'akiboRoutes';
import Layout from 'components/Layout/Layout';

const intlMessages = flattenMessages(frFRMessages);

const App = (): JSX.Element => {
  return (
    <Suspense fallback={<div />}>
      <ThemeProvider theme={muiTheme}>
        <IntlProvider locale="fr-FR" messages={intlMessages}>
          <CssBaseline />
          <Layout>
            <AkiboRoutes />
          </Layout>
        </IntlProvider>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
