import { InputMethod, InputUnit } from '@afleya/common';
import { CircularProgress, DialogActions, DialogContent } from '@mui/material';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAsync, useAsyncFn } from 'react-use';
import {
  formatEditMaterialInput,
  getEditMaterialInputFormDefaultValues,
  MaterialInputFormEntity,
  materialInputFormSchema,
} from 'services/materialInputs';
import { zodResolver } from '@hookform/resolvers/zod';
import { LocalMaterialInput } from 'services/materialInputs/types';
import { getMaterial } from 'services/offline/requests/material/getMaterial';
import { editLocalMaterialInput } from 'services/offline/requests/materialInput/editLocalMaterialInput';
import LoadingBox from 'components/LoadingBox';
import { FooterButton, FormContainer } from 'components/Layout';
import {
  InputSelectors,
  MaterialInputForm,
} from 'components/MaterialInputForm';
import { FormattedMessage } from 'react-intl';
import { CreateOrEditCharacteristic } from 'components/CreateOrEditCharacteristic';
import { Characteristic } from 'components/CreateOrEditCharacteristic/CharacteristicType';
import { editLocalCharacteristicInputs } from 'services/offline/requests/materialInput/editLocalCharacteristicInputs';
import {
  CharacteristicsEntity,
  CharacteristicValuesEntity,
} from '@afleya/material-schemas';
import { PlusCharacteristicButton } from 'components/PlusButtonCharacteristic';

interface Props {
  materialInput: LocalMaterialInput;
  onClose: () => void;
  onEditMaterialInput: (
    newEditMaterialInput: LocalMaterialInput,
  ) => Promise<void>;
  characteristicNames: CharacteristicsEntity[];
  characteristicValues: CharacteristicValuesEntity[];
  characteristicInputs: Characteristic[];
}

export const InputModuleSummaryEditForm = ({
  materialInput,
  onClose,
  onEditMaterialInput,
  characteristicNames,
  characteristicValues,
  characteristicInputs,
}: Props): JSX.Element => {
  const [inputMethod, setInputMethod] = useState<InputMethod>(
    materialInput.inputMethod,
  );
  const [inputUnit, setInputUnit] = useState<InputUnit>(
    materialInput.inputUnit,
  );
  const { value: material, loading: loadingMaterial } = useAsync(
    () => getMaterial(materialInput.materialId),
    [materialInput],
  );
  const [characteristics, setCharacteristics] =
    useState<Characteristic[]>(characteristicInputs);

  const [editCharacteristic, setEditCharacteristic] = useState<boolean>(false);
  const onClickCharacteristic = () => {
    setEditCharacteristic(true);
  };
  const {
    control,
    formState: { errors, isValidating },
    handleSubmit,
    getValues,
    setValue,
  } = useForm<MaterialInputFormEntity>({
    resolver: zodResolver(materialInputFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: getEditMaterialInputFormDefaultValues(materialInput),
  });
  const [{ loading: submitLoading }, onSubmit] = useAsyncFn(
    async (formAnswers: MaterialInputFormEntity) => {
      const localMaterialInput = formatEditMaterialInput(
        formAnswers,
        inputMethod,
        inputUnit,
      );
      await editLocalMaterialInput(materialInput.id, localMaterialInput);
      if (characteristics !== characteristicInputs) {
        await editLocalCharacteristicInputs(materialInput.id, characteristics);
      }
      await onEditMaterialInput({ ...materialInput, ...localMaterialInput });
    },
    [
      materialInput,
      onEditMaterialInput,
      inputMethod,
      inputUnit,
      characteristics,
      characteristicInputs,
    ],
  );

  return (
    <Fragment>
      <DialogContent>
        {loadingMaterial || !material ? (
          <LoadingBox />
        ) : (
          <FormContainer
            id="edit-material-input-form"
            noValidate
            autoComplete="off"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(onSubmit)}
          >
            <InputSelectors
              getValues={getValues}
              setValue={setValue}
              inputMethod={inputMethod}
              setInputMethod={setInputMethod}
              inputUnit={inputUnit}
              setInputUnit={setInputUnit}
            />
            <MaterialInputForm
              control={control}
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              inputMethod={inputMethod}
              inputUnit={inputUnit}
              projectId={materialInput.projectId}
              material={material}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {characteristicNames.length > 0 && (
                <PlusCharacteristicButton
                  onClickCharacteristic={onClickCharacteristic}
                ></PlusCharacteristicButton>
              )}
            </div>
          </FormContainer>
        )}
      </DialogContent>
      <DialogActions>
        <FooterButton variant="contained" color="secondary" onClick={onClose}>
          <FormattedMessage id="input-module-summary.dialog-edit.cancelButton" />
        </FooterButton>
        <FooterButton
          form="edit-material-input-form"
          type="submit"
          variant="contained"
          color="primary"
          disabled={isValidating || submitLoading}
        >
          {isValidating || submitLoading ? (
            <CircularProgress size={20} />
          ) : (
            <FormattedMessage id="input-module-summary.dialog-edit.submitButton" />
          )}
        </FooterButton>
      </DialogActions>
      {editCharacteristic && (
        <CreateOrEditCharacteristic
          setCharacteristics={setCharacteristics}
          characteristics={characteristics}
          setDisplayCreateCharacteristic={setEditCharacteristic}
          materialName={getValues('nickname')}
          characteristicNames={characteristicNames}
          characteristicValues={characteristicValues}
        />
      )}
    </Fragment>
  );
};
