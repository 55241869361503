import { Box, Drawer } from '@mui/material';
import {
  DrawerText,
  SuccessIcon,
  TopLine,
} from 'components/Layout/Layout.style';

interface Props {
  open?: boolean;
  title: string;
  children?: JSX.Element | JSX.Element[];
}

const SuccessDrawer = ({ open, title, children }: Props): JSX.Element => {
  return (
    <Drawer anchor="bottom" open={open}>
      <Box width="100%" display="flex" justifyContent="center">
        <TopLine color="primary" />
      </Box>
      <Box width="100%" display="flex" justifyContent="center">
        <SuccessIcon />
      </Box>
      <DrawerText>{title}</DrawerText>
      {children}
    </Drawer>
  );
};

export default SuccessDrawer;
