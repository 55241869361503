import { ProjectEntity } from '@afleya/project-schemas';
import { Fragment, useState } from 'react';
import { LocalMaterialInputWithNbPictures } from 'services/materialInputs/types';
import { useAsync } from 'react-use';
import {
  computeMaterialInputForRecapDimensions,
  computeMaterialInputForRecapLocation,
  listMaterialInputsForRecap,
  MaterialInputForRecap,
} from 'services/offline/materialInputsForRecap';
import filterService from 'services/filterService/filterService';
import { listCharacteristicInputs } from 'services/offline/requests/materialInput/listCharacteristicInputs';
import {
  InputModuleSummaryActions,
  InputModuleSummaryEditDialog,
  InputModuleSummaryPageContent,
  InputModuleSummaryPageHeader,
  InputModuleSummaryPicturesDialog,
} from './components';

interface Props {
  project: ProjectEntity;
  backlinkTitleId: string;
  buildingId?: string;
  buildingPartId?: string;
}

export const InputModuleSummary = ({
  project,
  backlinkTitleId,
  buildingId,
  buildingPartId,
}: Props): JSX.Element => {
  const [materialInputs, setMaterialInputs] = useState<
    Array<MaterialInputForRecap>
  >([]);

  const [filter, setFilter] = useState<string>('');
  const [characteristicFilter, setCharacteristicFilter] =
    useState<boolean>(false);

  const [selectedMaterialInputs, setSelectedMaterialInputs] = useState<
    Array<MaterialInputForRecap>
  >([]);

  const [editedMaterialInput, setEditedMaterialInput] =
    useState<MaterialInputForRecap>();

  // State for showing item actions dialog
  const [showPictures, setShowPictures] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  // States for showing group actions dialog
  const [showDuplicate, setShowDuplicate] = useState<boolean>(false);
  const [showMove, setShowMove] = useState<boolean>(false);
  const [showDuplicateAndMove, setShowDuplicateAndMove] =
    useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const onCloseEdit = () => {
    setEditedMaterialInput(undefined);
    setShowEdit(false);
  };

  const onClosePictures = (nbPictures: number) => {
    const index = materialInputs.findIndex(
      materialInput => materialInput.id === editedMaterialInput?.id,
    );

    materialInputs[index].nbPictures = nbPictures;

    setMaterialInputs(materialInputs);

    setEditedMaterialInput(undefined);
    setShowPictures(false);
  };

  const onEditMaterialInput = async (
    newEditMaterialInput: LocalMaterialInputWithNbPictures,
  ) => {
    const index = materialInputs.findIndex(
      materialInput => materialInput.id === newEditMaterialInput.id,
    );

    if (index !== -1) {
      materialInputs[index] = {
        ...newEditMaterialInput,
        location: await computeMaterialInputForRecapLocation({
          buildingId,
          buildingPartId,
          materialInputBuildingId: newEditMaterialInput.buildingId,
          materialInputBuildingPartId: newEditMaterialInput.buildingPartId,
          materialInputLocationDescription:
            newEditMaterialInput.locationDescription,
        }),
        dimensions: computeMaterialInputForRecapDimensions(
          newEditMaterialInput.inputMethod,
          newEditMaterialInput.inputUnit,
          newEditMaterialInput.dimensions,
        ),
        nbPictures: materialInputs[index].nbPictures,
        toDeduce: newEditMaterialInput.materialToDeduce,
        hasCharacteristic:
          (await listCharacteristicInputs(newEditMaterialInput.id)).length > 0,
      };
      setMaterialInputs(materialInputs);
    }

    onCloseEdit();
  };

  useAsync(async () => {
    const materialInputsChunk = await listMaterialInputsForRecap({
      projectId: project.id,
      buildingId,
      buildingPartId,
    });

    setMaterialInputs(materialInputsChunk);
  }, [project, buildingId, buildingPartId]);

  return (
    <Fragment>
      <InputModuleSummaryPageHeader
        project={project}
        buildingId={buildingId}
        buildingPartId={buildingPartId}
        backlinkTitleId={backlinkTitleId}
        setFilter={setFilter}
        setCharacteristicFilter={setCharacteristicFilter}
        setShowDuplicate={setShowDuplicate}
        setShowMove={setShowMove}
        setShowDuplicateAndMove={setShowDuplicateAndMove}
        setShowDelete={setShowDelete}
        disabledActions={selectedMaterialInputs.length === 0}
        characteristicFilter={characteristicFilter}
      />
      <InputModuleSummaryPageContent
        materialInputs={materialInputs.filter(materialInput => {
          const hasCharacteristic =
            !characteristicFilter || materialInput.hasCharacteristic;

          return (
            filterService(
              [materialInput.nickname, materialInput.location],
              filter,
            ) && hasCharacteristic
          );
        })}
        setEditedMaterialInput={setEditedMaterialInput}
        setShowEdit={setShowEdit}
        setShowPictures={setShowPictures}
        selectedMaterialInputs={selectedMaterialInputs}
        setSelectedMaterialInputs={setSelectedMaterialInputs}
      />
      {editedMaterialInput && showEdit && (
        <InputModuleSummaryEditDialog
          materialInputForRecap={editedMaterialInput}
          open={showEdit}
          onClose={onCloseEdit}
          onEditMaterialInput={onEditMaterialInput}
        />
      )}
      {editedMaterialInput && showPictures && (
        <InputModuleSummaryPicturesDialog
          open={showPictures}
          projectId={project.id}
          materialInput={editedMaterialInput}
          onClose={onClosePictures}
        />
      )}
      <InputModuleSummaryActions
        buildingId={buildingId}
        buildingPartId={buildingPartId}
        projectId={project.id}
        selectedMaterialInputs={selectedMaterialInputs}
        setSelectedMaterialInputs={setSelectedMaterialInputs}
        materialInputs={materialInputs}
        setMaterialInputs={setMaterialInputs}
        showDuplicate={showDuplicate}
        setShowDuplicate={setShowDuplicate}
        showMove={showMove}
        setShowMove={setShowMove}
        showDuplicateAndMove={showDuplicateAndMove}
        setShowDuplicateAndMove={setShowDuplicateAndMove}
        showDelete={showDelete}
        setShowDelete={setShowDelete}
      />
    </Fragment>
  );
};
