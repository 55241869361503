import { lazy } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import { routes } from 'routes';

import {
  HomePage,
  RequireAkibo,
  RequireAuth,
  RequireProjectOwner,
} from 'components/PrivateRoute';
import { ScrollToTop } from 'components/ScrollToTop';
import { InputModuleRoutes } from 'inputModulesRoutes';

// Login pages
const Login = lazy(() => import('./pages/Login'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));

// Akibo pages
const ListProjects = lazy(() => import('./pages/ListProjects'));
const ListOrganizationMaterials = lazy(
  () => import('pages/ListOrganizationMaterials'),
);
const DashboardClient = lazy(() => import('pages/DashboardClient'));
const EditMaterial = lazy(() => import('pages/EditMaterial'));
const EditBuildingConfiguration = lazy(
  () => import('pages/EditBuildingConfiguration'),
);
const CreateProject = lazy(() => import('pages/CreateProject'));
const ProjectBuidingsCount = lazy(() => import('pages/ProjectBuildingsCount'));
const ProjectBuildings = lazy(() => import('pages/ListProjectBuildings'));
const SetBuildingConfiguration = lazy(
  () => import('pages/SetBuildingConfiguration'),
);
const ProjectBuildingParts = lazy(() => import('pages/ListBuildingParts'));
const ProjectBuildingFloorsCount = lazy(
  () => import('pages/BuildingFloorsCount'),
);
const AnalysisModuleHomePage = lazy(
  () => import('pages/AnalysisModuleHomePage'),
);
const AnalysisModuleScenario = lazy(
  () => import('pages/AnalysisModuleScenario'),
);
const AnalysisModuleScenarioEdit = lazy(
  () => import('pages/AnalysisModuleScenarioEdit'),
);
const AnalysisModuleViewMap = lazy(() => import('pages/AnalysisModuleViewMap'));
const CreateRecyclingChannel = lazy(
  () => import('pages/CreateRecyclingChannel'),
);
const ManageOrganizationUsers = lazy(
  () => import('pages/ManageOrganizationUsers'),
);
const ManageOrganizationProjectAccesses = lazy(
  () => import('pages/ManageOrganizationProjectAccesses'),
);
const CreateUser = lazy(() => import('pages/CreateUser'));

// Obika pages
const ProjectOwnerListProjects = lazy(
  () => import('./pages/ProjectOwner/ProjectOwnerListProjects'),
);

const ProjectOwnerScenario = lazy(
  () => import('./pages/ProjectOwner/ProjectOwnerScenario'),
);

const AkiboRoutes = (): JSX.Element => (
  <Router>
    <Routes>
      <Route path={routes.LOGIN} element={<Login />} />
      <Route path={routes.CHANGE_PASSWORD} element={<ChangePassword />} />
      <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={routes.HOME_PAGE} element={<HomePage />} />
      <Route path="*" element={<Navigate to={routes.HOME_PAGE} />} />
      <Route element={<RequireAuth />}>
        <Route element={<RequireAkibo />}>
          <Route path={routes.LIST_PROJECTS} element={<ListProjects />} />
          <Route
            path={routes.LIST_ORGANIZATION_MATERIALS}
            element={<ListOrganizationMaterials />}
          />
          <Route path={routes.DASHBOARD_CLIENT} element={<DashboardClient />} />
          <Route path={routes.EDIT_MATERIAL} element={<EditMaterial />} />
          <Route
            path={routes.EDIT_BUILDING_CONFIGURATION}
            element={<EditBuildingConfiguration />}
          />
          <Route path={routes.CREATE_PROJECT} element={<CreateProject />} />
          <Route
            path={routes.PROJECT_BUILDINGS_COUNT}
            element={<ProjectBuidingsCount />}
          />
          <Route
            path={routes.PROJECT_BUILDINGS}
            element={<ProjectBuildings />}
          />
          <Route
            path={routes.SET_BUILDING_CONFIGURATION}
            element={<SetBuildingConfiguration />}
          />
          <Route
            path={routes.PROJECT_BUILDING_PARTS}
            element={<ProjectBuildingParts />}
          />
          <Route
            path={routes.PROJECT_BUILDING_FLOORS_COUNT}
            element={<ProjectBuildingFloorsCount />}
          />
          {InputModuleRoutes}
          <Route
            path={routes.ANALYSIS_MODULE_HOME_PAGE}
            element={<AnalysisModuleHomePage />}
          />
          <Route
            path={routes.ANALYSIS_MODULE_SCENARIO}
            element={<AnalysisModuleScenario />}
          />
          <Route
            path={routes.ANALYSIS_MODULE_SCENARIO_EDIT}
            element={<AnalysisModuleScenarioEdit />}
          />
          <Route
            path={routes.ANALYSIS_MODULE_VIEW_MAP}
            element={<AnalysisModuleViewMap />}
          />
          <Route
            path={routes.CREATE_RECYCLING_CHANNEL}
            element={<CreateRecyclingChannel />}
          />
          <Route
            path={routes.MANAGE_ORGANIZATION_USERS}
            element={<ManageOrganizationUsers />}
          />
          <Route
            path={routes.MANAGE_ORGANIZATION_PROJECT_ACCESSES}
            element={<ManageOrganizationProjectAccesses />}
          />
          <Route path={routes.CREATE_USER} element={<CreateUser />} />
        </Route>
        <Route element={<RequireProjectOwner />}>
          <Route
            path={routes.PROJECT_OWNER_LIST_PROJECTS}
            element={<ProjectOwnerListProjects />}
          />
        </Route>
        <Route
          path={routes.PROJECT_OWNER_SCENARIO}
          element={<ProjectOwnerScenario />}
        />
      </Route>
    </Routes>
    <ScrollToTop />
  </Router>
);

export default AkiboRoutes;
