import 'dexie-observable';
import 'dexie-syncable';
import './materialInputsSyncProtocol/materialInputsSyncProtocol';
import './materialInputsPicturesSyncProtocol';
import { store } from '../../store';
import { setValue } from '../store/unsynchonizedOperationsCounter';
import { SynchroDatabase } from './indexedDb/SynchroDatabase';
import { MaterialsDatabase } from './indexedDb/MaterialsDatabase';
import { MaterialsInputsPicturesDatabase } from './indexedDb/MaterialsInputsPicturesDatabase';
import { MaterialsInputsDatabase } from './indexedDb/MaterialsInputsDatabase';

const materialsDatabase = new MaterialsDatabase();
const materialInputsPicturesDatabase = new MaterialsInputsPicturesDatabase();
const materialsInputsDatabase = new MaterialsInputsDatabase();
const synchroDatabase = new SynchroDatabase();

try {
  void synchroDatabase
    .getUnsynchronizedOperations()
    .then(count => store.dispatch(setValue(count)));

  materialsInputsDatabase
    .startSync()
    .then(() => console.log('material input sync started'))
    .catch(error =>
      console.log('error while starting material input sync : ', error),
    );
  materialInputsPicturesDatabase
    .startSync()
    .then(() => console.log('material input picture sync started'))
    .catch(error =>
      console.log('error while starting material input picture sync : ', error),
    );
} catch (error) {
  console.log('dexie error: ', error);
}

export {
  materialsDatabase,
  materialsInputsDatabase,
  materialInputsPicturesDatabase,
  synchroDatabase,
};
