import { Grid, TextField } from '@mui/material';
import { Fragment } from 'react';
import {
  Control,
  Controller,
  FieldError,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { MaterialInputFormEntity } from 'services/materialInputs';
import { computePercentage } from '../libs';

interface Props {
  control: Control<MaterialInputFormEntity>;
  error?: FieldError;
  getValues: UseFormGetValues<MaterialInputFormEntity>;
  setValue: UseFormSetValue<MaterialInputFormEntity>;
}

export const States = ({
  control,
  error,
  getValues,
  setValue,
}: Props): JSX.Element => {
  const intl = useIntl();

  const GridItem = (
    fieldName:
      | 'stateNewPercentage'
      | 'stateGoodPercentage'
      | 'stateMediumPercentage'
      | 'stateObsoletePercentage',
    labelId: string,
  ): JSX.Element => (
    <Grid item xs={3} md={3} lg={3}>
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            variant="outlined"
            color="secondary"
            margin="normal"
            fullWidth
            required
            label={intl.formatMessage({
              id: `material-input-form.label.states.${labelId}`,
            })}
            placeholder={intl.formatMessage({
              id: 'material-input-form.placeholder.states',
            })}
            value={value}
            error={error !== undefined}
            helperText={
              fieldName === 'stateNewPercentage' && error !== undefined
                ? intl.formatMessage(
                    {
                      id: 'material-input-form.error.states',
                    },
                    { sumPercentage: error.message },
                  )
                : ''
            }
            onBlur={event => {
              if (fieldName !== 'stateGoodPercentage') {
                computePercentage(onChange, event, getValues, setValue);
              }
            }}
            onChange={event => {
              if (!isNaN(Number(event.currentTarget.value))) {
                onChange(Number(event.currentTarget.value));
              }
            }}
          />
        )}
      />
    </Grid>
  );

  return (
    <Fragment>
      <FormattedMessage id="material-input-form.label.states.legend" />
      <Grid container spacing={1}>
        {GridItem('stateNewPercentage', 'new')}
        {GridItem('stateGoodPercentage', 'good')}
        {GridItem('stateMediumPercentage', 'medium')}
        {GridItem('stateObsoletePercentage', 'obsolete')}
        <Grid item xs={12} md={12} lg={8}>
          <Controller
            name="stateComments"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="outlined"
                color="secondary"
                margin="normal"
                fullWidth
                type="string"
                label={intl.formatMessage({
                  id: 'material-input-form.label.states.stateComments',
                })}
                placeholder={intl.formatMessage({
                  id: 'material-input-form.placeholder.stateComments',
                })}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};
