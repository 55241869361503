export enum routes {
  HOME_PAGE = '/',
  LIST_PROJECTS = '/projects',
  LIST_ORGANIZATION_MATERIALS = '/organization-materials',
  DASHBOARD_CLIENT = '/dashboard-client',
  EDIT_MATERIAL = '/organization-materials/:materialId',
  EDIT_BUILDING_CONFIGURATION = '/projects/:projectId/buildings/:buildingId/edit-configuration',
  SET_BUILDING_CONFIGURATION = '/projects/:projectId/buildings/:buildingId/configuration',
  LOGIN = '/login',
  CHANGE_PASSWORD = '/password-change',
  FORGOT_PASSWORD = '/password-forgot',
  RESET_PASSWORD = '/password-reset',
  CREATE_PROJECT = '/projects/create',
  PROJECT_BUILDINGS_COUNT = '/projects/:projectId/buildings-count',
  PROJECT_BUILDINGS = '/projects/:projectId/buildings',
  PROJECT_BUILDING_PARTS = '/projects/:projectId/buildings/:buildingId/parts',
  PROJECT_BUILDING_FLOORS_COUNT = '/projects/:projectId/buildings/:buildingId/floors-count',
  INPUT_MODULE_HOME_PAGE = '/input/:projectId',
  INPUT_MODULE_HOME_PAGE_SUMMARY = '/input/:projectId/summary',
  INPUT_MODULE_BUILDING_PARTS = '/input/:projectId/:buildingId',
  INPUT_MODULE_BUILDING_PARTS_SUMMARY = '/input/:projectId/:buildingId/summary',
  INPUT_MODULE_CATEGORIES = '/input/:projectId/:buildingId/:buildingPartId/categories',
  INPUT_MODULE_CATEGORIES_SUMMARY = '/input/:projectId/:buildingId/:buildingPartId/summary',
  INPUT_MODULE_DUPLICATE_FLOOR = '/input/:projectId/:buildingId/:buildingPartId/duplicatefloor',
  INPUT_MODULE_DUPLICATE_BUILDING = '/input/:projectId/:buildingId/duplicatebuilding',
  INPUT_MODULE_EDIT_MAP = '/input/:projectId/:buildingPartId/edit-map/:buildingPartMapId/:onlyInputId',
  INPUT_MODULE_INPUT = '/input/:projectId/:buildingId/:buildingPartId/:categoryId/:materialId',
  INPUT_MODULE_INPUT_MAP_SELECT = '/input/:projectId/:buildingId/:buildingPartId/map-select/:materialInputId',
  INPUT_MODULE_INPUT_PHOTOS = '/input/:projectId/:buildingId/:buildingPartId/:materialInputId/photos',
  INPUT_MODULE_IMAGE_RECOGNITION = '/input/:projectId/:buildingId/:buildingPartId/image-recognition',
  INPUT_MODULE_CREATE_MATERIAL = '/input/create-material/:projectId/:buildingId/:buildingPartId',
  INPUT_MODULE_CREATE_BUILDING_PART = '/input/create-building-part/:projectId/:buildingId',
  INPUT_MODULE_EDIT_BUILDING_PART = '/input/edit-building-part/:projectId/:buildingId/:buildingPartId',
  INPUT_MODULE_DUPLICATE_BUILDING_PART = '/input/duplicate-building-part/:projectId/:buildingId/:buildingPartId',
  INPUT_MODULE_DUPLICATE_MOTHER_BUILDING_PART = '/input/duplicate-mother-building-part/:projectId/:buildingId/:buildingPartId',
  ANALYSIS_MODULE_HOME_PAGE = '/analysis/:projectId',
  ANALYSIS_MODULE_SCENARIO = '/analysis/:projectId/:scenarioId',
  ANALYSIS_MODULE_SCENARIO_EDIT = '/analysis/:projectId/:scenarioId/edit',
  ANALYSIS_MODULE_VIEW_MAP = '/analysis/:projectId/view-map/:buildingPartId/:buildingPartMapId',
  CREATE_RECYCLING_CHANNEL = '/analysis/:projectId/:scenarioId/:materialComponentId/create-recycling-channel',
  MANAGE_ORGANIZATION_USERS = '/manage-organization-users',
  MANAGE_ORGANIZATION_PROJECT_ACCESSES = '/manage-organization-project-accesses',
  CREATE_USER = '/create-user',
  PROJECT_OWNER_LIST_PROJECTS = '/project-owner/projects',
  PROJECT_OWNER_SCENARIO = '/project-owner/:projectId/scenario/:scenarioId',
}
