import {
  CreateMaterialRequestEntity,
  MaterialCategoryEntity,
} from '@afleya/material-schemas';
import { Autocomplete, TextField } from '@mui/material';
import BoxAlert from 'components/BoxAlert';
import LoadingBox from 'components/LoadingBox';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useAsync } from 'react-use';
import { listCategories } from 'services/offline/requests/material/listCategories';
import filterService from 'services/filterService/filterService';
import getCategoryName from 'services/GetMotherCategory/getCategoryName';

interface Props {
  control: Control<CreateMaterialRequestEntity>;
}

export const CategoryIdField = ({ control }: Props): JSX.Element => {
  const intl = useIntl();

  const {
    loading,
    error,
    value: data,
  } = useAsync(async () => {
    const categories = await listCategories();
    const categoriesWithoutMother = categories.filter(
      category => category.motherId !== null,
    );

    return { categoriesWithoutMother, categories };
  });

  const display = (daughterCategory: string, motherCategory: string) => {
    return daughterCategory + ' (' + motherCategory + ')';
  };

  const renderOptions = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: MaterialCategoryEntity,
  ) => {
    return (
      <li {...props} key={option.id}>
        {display(
          option.name,
          getCategoryName(data?.categories, option.motherId),
        )}
      </li>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <BoxAlert contentId={'select-material-category-form.loadingError'} />
      ) : (
        <Controller
          name="categoryId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              id="categoryId"
              sx={{ marginTop: '16px', marginBottom: '8px' }}
              options={data?.categoriesWithoutMother ?? []}
              getOptionLabel={option =>
                display(
                  option.name,
                  getCategoryName(data?.categories, option.motherId),
                )
              }
              renderInput={params => (
                <TextField
                  data-test="materialCategory"
                  {...params}
                  label={intl.formatMessage({
                    id: 'select-material-category-form.category',
                  })}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              value={
                data?.categoriesWithoutMother.find(d => d.id === value) ?? null
              }
              onChange={(_event, item) => {
                onChange(item?.id);
              }}
              renderOption={renderOptions}
              filterOptions={(options, state) =>
                options.filter(option =>
                  filterService(option.name.split(' '), state.inputValue),
                )
              }
            />
          )}
        />
      )}
    </>
  );
};
