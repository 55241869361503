import AkiboFooter from 'components/AkiboFooter';
import { MainContainer, ParentDiv } from './Layout.style';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const Layout = ({ children }: Props): JSX.Element => {
  return (
    <ParentDiv>
      <MainContainer sx={{ minHeight: '100vh' }}>{children}</MainContainer>
      <AkiboFooter></AkiboFooter>
    </ParentDiv>
  );
};
export default Layout;
