import { InputMethod, InputUnit } from '@afleya/common';
import { InputAdornment, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { rpn } from 'services/calculator';
import { MaterialInputFormEntity } from 'services/materialInputs';
import { ReactNode } from 'react';
import { getUnitToDisplay } from '../libs';

interface Props {
  control: Control<MaterialInputFormEntity>;
  fieldName: 'length' | 'width' | 'height';
  inputMethod: InputMethod;
  inputUnit: InputUnit;
}

export const DimensionField = ({
  control,
  fieldName,
  inputMethod,
  inputUnit,
}: Props): JSX.Element => {
  const intl = useIntl();
  const unitToDisplay = getUnitToDisplay(inputUnit, inputMethod);

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          data-test={fieldName}
          variant="outlined"
          color="secondary"
          margin="normal"
          label={intl.formatMessage({
            id: `material-input-form.label.${fieldName}`,
          })}
          placeholder={intl.formatMessage({
            id: `material-input-form.placeholder.${fieldName}`,
          })}
          value={value}
          error={error !== undefined}
          helperText={
            error !== undefined
              ? intl.formatMessage({
                  id: `material-input-form.error.${fieldName}`,
                })
              : ''
          }
          onChange={onChange}
          onBlur={event => {
            const result = rpn(event.currentTarget.value.replace(',', '.'));
            if (result !== '') {
              onChange(result);
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ fontWeight: 'bold', color: 'red' }}
              >
                <FormattedMessage
                  id={`material-input-form.unit.${unitToDisplay}`}
                  values={{ u: (chunks: ReactNode) => <sup>{chunks}</sup> }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
