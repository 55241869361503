// schemas/requests/CreateMaterialRequestEntity.ts
import {
  MaterialEvaluationMethod,
  NumberWithComma,
  WasteCategory
} from "@afleya/common";
import { nativeEnum, number, object, preprocess, string } from "zod";
var createMaterialRequestSchema = object({
  name: string().min(1),
  categoryId: string().uuid(),
  composition: object({
    componentId: string().uuid(),
    percentage: preprocess(NumberWithComma, number().min(0).max(100))
  }).array().nonempty(),
  evaluationMethod: nativeEnum(MaterialEvaluationMethod),
  defaultWasteCategory: nativeEnum(WasteCategory),
  weightPerFunctionalUnit: preprocess(
    NumberWithComma,
    number().nonnegative().safe()
  ),
  reuseAvoidedCO2PerFunctionalUnit: preprocess(
    NumberWithComma,
    number().nonnegative().safe()
  ),
  initialCostPerFunctionalUnit: preprocess(
    NumberWithComma,
    number().nonnegative().safe()
  ),
  selectiveRemovalCostPerFunctionalUnit: preprocess(
    NumberWithComma,
    number().nonnegative().safe()
  ),
  comments: string().optional()
});

// schemas/requests/createRecyclingChannel.ts
var createRecyclingChannelEntitySchema = {
  type: "object",
  properties: {
    name: { type: "string" },
    materialComponentId: { type: "string" },
    recyclingPercentage: { type: "number" },
    valorizedIncinerationPercentage: { type: "number" },
    comments: { type: "string" },
    collector: { type: "string" },
    actor: { type: "string" },
    packaging: { type: "string" },
    removalCondition: { type: "string" },
    acceptedWaste: { type: "string" },
    rejectedWaste: { type: "string" },
    refillingPercentage: { type: "number" }
  },
  required: [
    "name",
    "materialComponentId",
    "recyclingPercentage",
    "valorizedIncinerationPercentage",
    "refillingPercentage"
  ],
  additionalProperties: false
};

// schemas/entities/AssemblyTypeEntity.ts
var assemblyTypeEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" }
  },
  required: ["id", "name"],
  additionalProperties: false
};

// schemas/entities/ChecksumMaterialsEntity.ts
import { object as object2, string as string2 } from "zod";
var checksumMaterialsSchema = object2({
  id: string2(),
  assemblyTypes: string2().length(64),
  categories: string2().length(64),
  components: string2().length(64),
  akiboMaterials: string2().length(64),
  organizationMaterials: string2().length(64),
  characteristics: string2().length(64),
  characteristicValues: string2().length(64),
  imageRecognitionLabels: string2().length(64)
});

// schemas/entities/ImageRecognitionLabelEntity.ts
import { MaterialEvaluationMethod as MaterialEvaluationMethod2 } from "@afleya/common";
var imageRecognitionLabelSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    labelIndex: { type: "number" },
    name: { type: "string" }
  },
  required: ["id", "labelIndex", "name"],
  additionalProperties: false
};

// schemas/entities/ListValue.ts
import { ListName } from "@afleya/common";
var listValueEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    listName: { enum: Object.values(ListName) },
    value: { type: "string" }
  },
  required: ["id", "listName", "value"],
  additionalProperties: false
};

// schemas/entities/materialCategoryEntity.ts
var materialCategoryEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    motherId: { type: ["string", "null"] },
    imageRecognitionLabelId: { type: ["string", "null"] }
  },
  required: ["id", "name", "motherId", "imageRecognitionLabelId"],
  additionalProperties: false
};

// schemas/entities/materialComponent.ts
var materialComponentEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    wasteCode: { type: ["string", "null"] },
    comments: { type: ["string", "null"] }
  },
  required: ["id", "name"],
  additionalProperties: false
};

// schemas/entities/materialComponentRecycling.ts
import { WasteCategory as WasteCategory2 } from "@afleya/common";
var materialComponentRecyclingEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    weight: { type: "number" },
    comments: { type: ["string", "null"] },
    wasteCategory: { enum: Object.values(WasteCategory2) },
    defaultRecyclingChannelId: { type: ["string", "null"] }
  },
  required: [
    "id",
    "name",
    "weight",
    "comments",
    "wasteCategory",
    "defaultRecyclingChannelId"
  ],
  additionalProperties: false
};

// schemas/entities/materialEntity.ts
import { MaterialEvaluationMethod as MaterialEvaluationMethod3, WasteCategory as WasteCategory3 } from "@afleya/common";
import { boolean, nativeEnum as nativeEnum2, number as number2, object as object3, string as string3 } from "zod";
var materialEntitySchema = object3({
  id: string3(),
  name: string3(),
  reuseCondition: string3().nullable(),
  tags: string3(),
  categoryId: string3(),
  relevance: number2(),
  evaluationMethod: nativeEnum2(MaterialEvaluationMethod3),
  organizationId: string3().nullable(),
  defaultWasteCategory: nativeEnum2(WasteCategory3),
  isValidated: boolean(),
  generic: boolean()
});

// schemas/entities/materialEntityToEdit.ts
import { MaterialEvaluationMethod as MaterialEvaluationMethod4, WasteCategory as WasteCategory4 } from "@afleya/common";
var materialEntityToEditSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    categoryId: { type: "string" },
    composition: {
      type: "array",
      items: {
        type: "object",
        properties: {
          componentId: { type: "string" },
          percentage: { type: "number" }
        },
        required: ["componentId", "percentage"]
      }
    },
    evaluationMethod: { enum: Object.values(MaterialEvaluationMethod4) },
    defaultWasteCategory: { enum: Object.values(WasteCategory4) },
    weightPerFunctionalUnit: { type: "number" },
    reuseAvoidedCO2PerFunctionalUnit: { type: "number" },
    initialCostPerFunctionalUnit: { type: "number" },
    selectiveRemovalCostPerFunctionalUnit: { type: "number" },
    comments: { type: ["string", "null"] },
    isValidated: { type: "boolean" }
  },
  required: [
    "id",
    "name",
    "categoryId",
    "composition",
    "evaluationMethod",
    "defaultWasteCategory",
    "weightPerFunctionalUnit",
    "reuseAvoidedCO2PerFunctionalUnit",
    "initialCostPerFunctionalUnit",
    "selectiveRemovalCostPerFunctionalUnit",
    "comments",
    "isValidated"
  ],
  additionalProperties: false
};

// schemas/entities/materialEntityWithNumericFields.ts
import { MaterialEvaluationMethod as MaterialEvaluationMethod5, WasteCategory as WasteCategory5 } from "@afleya/common";
var materialEntityWithNumericFieldsSchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    weightPerFunctionalUnit: { type: "number" },
    evaluationMethod: { enum: Object.values(MaterialEvaluationMethod5) },
    defaultWasteCategory: { enum: Object.values(WasteCategory5) }
  },
  required: [
    "id",
    "weightPerFunctionalUnit",
    "evaluationMethod",
    "defaultWasteCategory"
  ],
  additionalProperties: false
};

// schemas/entities/organizationMaterialEntity.ts
import { boolean as boolean2, object as object4, string as string4 } from "zod";
var organizationMaterialEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    isValidated: { type: "boolean" },
    organizationName: { type: "string" },
    emailOfCreator: { type: "string" },
    createdAt: { type: "string" },
    emailOfValidator: { type: "string" },
    validatedAt: { type: "string" }
  },
  required: [
    "id",
    "name",
    "organizationName",
    "isValidated",
    "emailOfCreator",
    "createdAt",
    "emailOfValidator",
    "validatedAt"
  ],
  additionalProperties: false
};
var organizationMaterialEntityWithOrganizationIdSchema = object4({
  id: string4(),
  name: string4(),
  isValidated: boolean2(),
  organizationName: string4(),
  emailOfCreator: string4(),
  createdAt: string4(),
  emailOfValidator: string4().optional(),
  validatedAt: string4().optional(),
  organizationId: string4()
});

// schemas/entities/recyclingChannel.ts
var recyclingChannelEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    recyclingPercentage: { type: "number" },
    valorizedIncinerationPercentage: { type: "number" },
    comments: { type: ["string", "null"] },
    materialComponentId: { type: "string" },
    collector: { type: ["string", "null"] },
    actor: { type: ["string", "null"] },
    packaging: { type: ["string", "null"] },
    removalCondition: { type: ["string", "null"] },
    acceptedWaste: { type: ["string", "null"] },
    rejectedWaste: { type: ["string", "null"] },
    refillingPercentage: { type: "number" }
  },
  required: [
    "id",
    "name",
    "recyclingPercentage",
    "valorizedIncinerationPercentage",
    "comments",
    "materialComponentId",
    "collector",
    "actor",
    "packaging",
    "removalCondition",
    "acceptedWaste",
    "rejectedWaste",
    "refillingPercentage"
  ],
  additionalProperties: false
};

// schemas/entities/reuseChannel.ts
import { ReuseChannelTypes } from "@afleya/common";
var reuseChannelEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    costDiscount: { type: "number" },
    co2Discount: { type: "number" },
    comments: { type: ["string", "null"] },
    reuseType: { enum: Object.values(ReuseChannelTypes) },
    defaultChannel: { type: "boolean" }
  },
  required: [
    "id",
    "name",
    "comments",
    "costDiscount",
    "co2Discount",
    "reuseType",
    "defaultChannel"
  ],
  additionalProperties: false
};

// schemas/entities/reuseActorsEntity.ts
import { ReuseActorChalandise, ReuseActorPlatformType } from "@afleya/common";
var reuseActorsEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    latitude: { type: "number" },
    longitude: { type: "number" },
    address: { type: "string" },
    zipCode: { type: "number" },
    email: { type: "string" },
    phone: { type: "string" },
    url: { type: "string" },
    source: {
      type: "array",
      items: {
        type: "string"
      }
    },
    treatment: { type: "string" },
    precisionActor: { type: "string" },
    chalandise: {
      type: "string",
      enum: Object.values(ReuseActorChalandise)
    },
    reuse: { type: "boolean" },
    repurpose: { type: "boolean" },
    recycling: { type: "boolean" },
    organizationId: { type: ["string", "null"] },
    generalist: { type: "boolean" },
    distance: { type: ["number", "null"] },
    platformType: {
      type: "string",
      enum: Object.values(ReuseActorPlatformType)
    },
    city: { type: "string" }
  },
  required: [
    "id",
    "name",
    "latitude",
    "longitude",
    "address",
    "zipCode",
    "email",
    "phone",
    "url",
    "source",
    "treatment",
    "precisionActor",
    "chalandise",
    "reuse",
    "repurpose",
    "recycling",
    "organizationId",
    "generalist",
    "distance",
    "platformType",
    "city"
  ],
  additionalProperties: false
};

// schemas/entities/characteristicValuesEntity.ts
var characteristicValuesEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    characteristicId: { type: "string" },
    categoryId: { type: "string" }
  },
  required: ["id", "name", "characteristicId", "categoryId"],
  additionalProperties: false
};

// schemas/entities/characteristicsEntity.ts
var characteristicsEntitySchema = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    position: { type: "number" },
    categoryId: { type: "string" }
  },
  required: ["id", "name", "position", "categoryId"],
  additionalProperties: false
};
export {
  assemblyTypeEntitySchema,
  characteristicValuesEntitySchema,
  characteristicsEntitySchema,
  checksumMaterialsSchema,
  createMaterialRequestSchema,
  createRecyclingChannelEntitySchema,
  imageRecognitionLabelSchema,
  listValueEntitySchema,
  materialCategoryEntitySchema,
  materialComponentEntitySchema,
  materialComponentRecyclingEntitySchema,
  materialEntitySchema,
  materialEntityToEditSchema,
  materialEntityWithNumericFieldsSchema,
  organizationMaterialEntitySchema,
  organizationMaterialEntityWithOrganizationIdSchema,
  recyclingChannelEntitySchema,
  reuseActorsEntitySchema,
  reuseChannelEntitySchema
};
